export const COLOR = {
    // Estos colores se usan en TODO el proyecto cambiar SOLO cuando sea necesario
    blanco: '#FFFFFF',
    negro: '#000000',
    azul:'#051c2c',
    grisTarjetaBienvenido: '#CCCCCC',
    doradoNL: '#c4a57b',
    grisDivisionEntreElementos: '#808080',
    grisBotones:'#EEEEEE',
    rojo:'#fa4238',
    verde:'#23FE09',
    verdeBAndera:'#229954 ',

  }