export const ls = {
  signIn: "TESORERÍA VIRTUAL",
  secondaryText: "Ingresa tus datos de acceso para iniciar sesión:",
  placeholderUser: "Usuario o correo electrónico",
  placeholderPass: "Contraseña",
  btnText: "Ingresar",
  forgot: "¿Olvidaste tu contraseña?",
  footerSecondText: "Aviso de Privacidad",
  footerThirdText: "Contacto",
};
